export const rows = [
  {
    id: 1,
    quantityPerUnit: 102,
    name: "Jon",
    defective: 35,
    purchaseDate: "28/8/2023",
    MEDEXP: "28/8/2023, 28/8/2023",
    dateEXP: 30,
    vat: "Vat",
    category: "cate 1",
    countingUnit: "กระป๋อง",
    volumnPerUnit: "250 ml",
    operationFee: 50,
    operationFeePerUnit: 0,
    rawPrice: 50,
    rawPricePerUnit: 0,
    cost: 100,
    costPerUnit: 10,
    profit: "30%",
    expectedSellingPrice: 13.91,
    actualSellingPrice: 14,
  },
  {
    id: 2,
    quantityPerUnit: 102,
    name: "Cersei",
    defective: 42,
    purchaseDate: "28/8/2023",
    MEDEXP: "28/8/2023, 28/8/2023",
    dateEXP: 30,
    vat: "Vat",
    category: "cate 2",
    countingUnit: "กระป๋อง",
    volumnPerUnit: "250 ml",
    operationFee: 50,
    operationFeePerUnit: 0,
    rawPrice: 50,
    rawPricePerUnit: 0,
    cost: 100,
    costPerUnit: 10,
    profit: "30%",
    expectedSellingPrice: 13.91,
    actualSellingPrice: 14,
  },
  {
    id: 3,
    quantityPerUnit: 102,
    name: "Jaime",
    defective: 45,
    purchaseDate: "28/8/2023",
    MEDEXP: "28/8/2023, 28/8/2023",
    dateEXP: 30,
    vat: "Vat",
    category: "cate 3",
    countingUnit: "กระป๋อง",
    volumnPerUnit: "250 ml",
    operationFee: 50,
    operationFeePerUnit: 0,
    rawPrice: 50,
    rawPricePerUnit: 0,
    cost: 100,
    costPerUnit: 10,
    profit: "30%",
    expectedSellingPrice: 13.91,
    actualSellingPrice: 14,
  },
  {
    id: 4,
    quantityPerUnit: 102,
    name: "Arya",
    defective: 16,
    purchaseDate: "28/8/2023",
    MEDEXP: "28/8/2023, 28/8/2023",
    dateEXP: 30,
    vat: "Vat",
    category: "cate 4",
    countingUnit: "กระป๋อง",
    volumnPerUnit: "250 ml",
    operationFee: 50,
    operationFeePerUnit: 0,
    rawPrice: 50,
    rawPricePerUnit: 0,
    cost: 100,
    costPerUnit: 10,
    profit: "30%",
    expectedSellingPrice: 13.91,
    actualSellingPrice: 14,
  },
  {
    id: 5,
    quantityPerUnit: 102,
    name: "Daenerys",
    defective: null,
    purchaseDate: "28/8/2023",
    MEDEXP: "28/8/2023, 28/8/2023",
    dateEXP: 30,
    vat: "Vat",
    category: "cate 5",
    countingUnit: "กระป๋อง",
    volumnPerUnit: "250 ml",
    operationFee: 50,
    operationFeePerUnit: 0,
    rawPrice: 50,
    rawPricePerUnit: 0,
    cost: 100,
    costPerUnit: 10,
    profit: "30%",
    expectedSellingPrice: 13.91,
    actualSellingPrice: 14,
  },
  {
    id: 6,
    quantityPerUnit: 102,
    name: "Ley",
    defective: 150,
    purchaseDate: "28/8/2023",
    MEDEXP: "28/8/2023, 28/8/2023",
    dateEXP: 30,
    vat: "Vat",
    category: "cate 6",
    countingUnit: "กระป๋อง",
    volumnPerUnit: "250 ml",
    operationFee: 50,
    operationFeePerUnit: 0,
    rawPrice: 50,
    rawPricePerUnit: 0,
    cost: 100,
    costPerUnit: 10,
    profit: "30%",
    expectedSellingPrice: 13.91,
    actualSellingPrice: 14,
  },
];
